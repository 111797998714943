<template>
    <div class="team-card row mx-0">
        <div class="px-0 col-12 col-md-3">
            <img :src="getPortrait()" class="portrait" alt="portrait" />
        </div>
        <div class="description col-12 col-md-9">
            <h4 class="fw-bold">{{ name }}</h4>
            <p v-html="role" />
            <p v-html="bio" />
            <p v-if="bio2" v-html="bio2" />
            <p>
                Find me on <a :href="linkedIn" target="_blank" rel="noopener noreferrer" class="fw-bold text-decoration-underline">LinkedIn</a
                ><img src="../assets/externalLink.svg" alt="externalLink" class="external-link-icon" />
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TeamCard',
        props: {
            name: {
                type: String,
                required: true,
            },
            role: {
                type: String,
                required: true,
            },
            bio: {
                type: String,
                required: true,
            },
            bio2: {
                type: String,
                required: false,
            },
            linkedIn: {
                type: String,
                required: true,
            },
            portrait: {
                type: String,
                required: true,
            },
        },
        methods: {
            getPortrait() {
                return require(`../assets/${this.portrait}`)
            },
        },
    }
</script>
