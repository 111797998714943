<template>
    <div class="section">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'SectionBlock',
    }
</script>
