<template>
    <div class="icon-card">
        <div class="mb-3">
            <img :src="getIcon()" alt="icon" />
        </div>
        <h5 v-if="!hero" class="fw-bold font-align-normal">{{ title }}</h5>
        <h4 v-else class="fw-bold font-align-normal">{{ title }}</h4>
        <p v-html="description" />
    </div>
</template>

<script>
    export default {
        name: 'IconCard',
        props: {
            title: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
            icon: {
                type: String,
                required: true,
            },
            hero: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            getIcon() {
                return require(`../assets/${this.icon}`)
            },
        },
    }
</script>
