export const utilMixin = {
    methods: {
        scrollToTop: function () {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        },
    },
}
