<template>
    <div class="drawer-card">
        <div class="drawer-handle" />
        <h5 class="fw-bold mb-2">{{ title }}</h5>
        <p>{{ description }}</p>
    </div>
</template>

<script>
    export default {
        name: 'DrawerCard',
        props: {
            title: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
        },
    }
</script>
