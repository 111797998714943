<template>
    <div class="program-card mx-0">
        <div class="image-container px-0">
            <img :src="getImage()" class="image" alt="image" />
            <img :src="getLogo()" class="program-logo" alt="logo" />
        </div>
        <div class="description">
            <h4 class="pb-1 fw-semibold">{{ name }}</h4>
            <p class="small" v-html="description" />
            <p class="pt-2 pb-0 fw-normal">Line of Business</p>
            <p class="pt-0 pb-2" v-html="lineOfBusiness" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProgramCard',
        props: {
            name: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
            lineOfBusiness: {
                type: String,
                required: true,
            },
            logo: {
                type: String,
                required: true,
            },
            image: {
                type: String,
                required: true,
            },
        },
        methods: {
            getLogo() {
                return require(`../assets/${this.logo}`)
            },
            getImage() {
                return require(`../assets/${this.image}`)
            },
        },
    }
</script>
