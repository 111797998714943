<template>
    <section-block class="hero programs">
        <div class="fw-bold small">Outcomes & Success</div>
        <h2>Desq is helping talented underwriters launch programs in a range of industries.</h2>
    </section-block>
    <section-block class="card-container">
        <h2>Launched Programs</h2>
        <div class="row mt-4">
            <div class="col-12 col-md-4">
                <program-card
                    name="Pera"
                    description="Pera provides excess cyber and technology errors and omissions insurance to large U.S. domiciled corporate risks, generally >$1B in annual revenue. Our underwriting appetite is open to most industry classes, and prioritizes entities with mature cybersecurity controls."
                    line-of-business="XS Cyber"
                    logo="pera.png"
                    image="lockedPhone.png"
                />
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '@/components/SectionBlock'
    import ProgramCard from '@/components/ProgramCard.vue'
    export default {
        name: 'Programs',
        components: { ProgramCard, SectionBlock },
    }
</script>

<style scoped></style>
