import { createWebHistory, createRouter } from 'vue-router'
import Launch from '@/pages/Launch'
import Partner from '@/pages/Partner'
import Team from '@/pages/Team'
import Programs from '@/pages/Programs'
import Contact from '@/pages/Contact.vue'
import PrivacyPolicy from '@/pages/PrivacyPolicy'

const routes = [
    {
        path: '/',
        name: 'Launch',
        component: Launch,
    },
    {
        path: '/partner',
        name: 'Partner',
        component: Partner,
    },
    {
        path: '/team',
        name: 'Team',
        component: Team,
    },
    {
        path: '/programs',
        name: 'Programs',
        component: Programs,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
