<template>
    <section-block class="hero base-background">
        <h2>Terms & Conditions</h2>
        <div>
            <p class="mb-4">
                Please read these Terms of Use (“Terms”) carefully before using the Websites. For the purposes of these Terms, the term, “Websites”, shall refer collectively to
                <a href="https://www.desquw.com" target="_blank" rel="noopener noreferrer" class="text-decoration-underline">www.desquw.com</a> as well as the other websites that Desq Insurance
                Services, LLC. and our affiliates and subsidiaries (“Desq,” “we,” “us,” or “our”) operates and that link to these Terms of Use.
            </p>
            <p class="mb-4">
                By accessing or using the Websites or any of the content on the Websites you agree to be legally bound by these Terms. If you do not accept these Terms, do not use the Websites or any
                of its Content (defined below).
            </p>
            <p class="mb-4">
                You represent to Desq that you are lawfully able to enter into contracts (e.g., you are not a minor). If you are entering into these Terms for an entity, such as the company you work
                for, you represent that you have authority to bind that entity and you agree that “you” as used in these Terms includes both you personally and the entity you represent. You and Desq
                are collectively referred to as the “Parties” and each is a “Party”.
            </p>
            <h4>Master Subscription Agreement.</h4>
            <p class="mb-4">These Terms do not govern the use of Desq’s customer service solution as well as other hosted services (collectively, the “Service”) operated by Desq.</p>
            <h4>Desq Content</h4>
            <p class="mb-4">
                The Websites contain HTML, applications, messages, text, files, images, photos, video, sounds, profiles, works of authorship and other content (collectively, “Content”) of Desq or its
                licensors (“Desq Content”). The Websites (including the Desq Content) are protected by copyright, trademark, trade secret and other laws; and as between you and Desq, Desq owns and
                retains all rights in the Websites and the Desq Content. Desq hereby grants to you a limited, revocable, non-sublicensable license to access, display and perform the Desq Content
                (excluding any computer code) solely for your personal, non-commercial use and solely as necessary to access and use the Websites. Except as expressly permitted by Desq in these Terms
                or on the Websites, you may not copy, download, stream, capture, reproduce, duplicate, archive, upload, modify, translate, create derivative works based upon, publish, broadcast,
                transmit, retransmit, distribute, perform, display, sell or otherwise use or transfer any Desq Content. You may not, either directly or through the use of any device, software, online
                resource or other means, remove, alter, bypass, avoid, interfere with or circumvent any copyright, trademark or other proprietary notice on the Desq Content or any digital rights
                management mechanism, device, or other content protection or access control measure associated with the Desq Content.
            </p>
            <h4>Trademarks.</h4>
            <p class="mb-4">
                The trademarks, logos and service marks (“Marks”) displayed on the Websites are the property of Desq or other third parties. You are not permitted to use these Marks without the prior
                written consent of Desq or such third party.
            </p>
            <h4>Third-Party Services.</h4>
            <p class="mb-4">
                The Websites may make available, or third parties may provide, links to other websites, applications, resources, advertisements, Content or other products or services created, hosted
                or made available by third parties (“Third-Party Services”), and such third parties may use other third parties to provide portions of the Third-Party Service to you, such as
                technology, development or payment services. When you access or use a Third-Party Service, you are interacting with the applicable third party, not with Desq, and you do so at your own
                risk. Desq is not responsible for, and makes no warranties, express or implied, as to, the Third-Party Services or the providers of such Third-Party Services (including without
                limitation the accuracy or completeness of the information provided by such Third-Party Service or the privacy practices of any third party). Inclusion of any Third-Party Service or a
                link thereto on the Websites does not imply approval or endorsement of such Third-Party Service. Desq is not responsible or liable for the Content or practices of any Third-Party
                Service or third party, even if such Third-Party Service links to, or is linked by, the Websites.
            </p>
            <h4>Privacy.</h4>
            <p class="mb-4">
                Please review Desq’s Privacy Policy for the Websites (“Privacy Policy”), available at
                <a href="https://www.desquw.com/policy" target="_blank" rel="noopener noreferrer" class="text-decoration-underline">www.desquw.com/policy</a>, to learn about Desq’s information
                collection, usage and disclosures practices with respect to information collected by Desq through the Websites.
            </p>
            <h4>Your Content.</h4>
            <p class="mb-4">
                By posting, displaying, publishing or making available for download or use any Content on the Websites (other than personal information that is subject to the Privacy Policy), you
                hereby grant Desq a perpetual, worldwide, nonexclusive, irrevocable, royalty-free, sublicensable (through multiple tiers) license to perform, display, reproduce, prepare derivative
                works from, distribute, sell, sublicense, transfer and otherwise use without restriction all or any part of such Content.
            </p>
            <h4>Acceptable Use.</h4>
            <p class="mb-4">
                Desq is not responsible or liable for any user Content or conduct on the Websites. If you become aware of any misuse of the Websites, please report such misuse immediately to Desq at
                info@desquw.com.
            </p>
            <h4>Compliance with Laws.</h4>
            <p class="mb-4">
                You represent that, in agreeing to, and performing under, these Terms, you are not violating, and will not violate, any governmental laws, rules, regulations or orders that are
                applicable to your use of the Websites (“Applicable Laws”). Without limiting the foregoing, you represent that, in connection with your performance under these Terms, you shall: (a)
                comply with Applicable Laws relating to anti-bribery and anti-corruption, which may include the US Foreign Corrupt Practices Act of 1977 and the UK Bribery Act 2010; (b) comply with
                Applicable Laws administered by the U.S. Commerce Bureau of Industry and Security, U.S. Treasury Office of Foreign Assets Control or other governmental entity imposing export controls
                and trade sanctions (“Export Laws”), including designating countries, entities and persons (“Sanctions Targets”); and (c) not directly or indirectly export, re-export or otherwise
                deliver any Desq software, content or services to a Sanctions Target, or broker, finance or otherwise facilitate any transaction in violation of any Export Laws. You represent that you
                are not a Sanctions Target or prohibited from receiving Desq software, content or services pursuant to these Terms under Applicable Laws, including Export Laws.
            </p>
            <h4>Global Availability.</h4>
            <p class="mb-4">
                Desq controls the Websites from its New York, NY, USA offices. If you use this website from other locations, you are responsible for compliance with local Applicable Laws. Desq makes
                no representation that the products and services referenced herein are appropriate, or available, worldwide.
            </p>
            <h4>Indemnity.</h4>
            <p class="mb-4">
                You agree to defend, indemnify and hold harmless Desq, its affiliates and their respective employees, contractors, agents, officers and directors from and against any and all claims,
                damages, obligations, losses, liabilities, costs, debt or expenses (including without limitation attorneys’ fees) arising out of or related to any claim, suit, action or proceeding by
                a third party arising out of or relating to your use of the Websites, breach of these Terms (including any Desq policy referenced in these Terms), violation of law, or any Content that
                you post, upload or cause to interface with the Websites, or otherwise transfer, process, use or store in connection with the Websites.
            </p>
            <h4>Disclaimers.</h4>
            <p class="mb-4">
                THE WEBSITES AND ALL CONTENT AND OTHER ITEMS MADE AVAILABLE BY Desq ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED,
                STATUTORY, OR OTHERWISE, INCLUDING ANY IMPLIED WARRANTY OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                LAW. Desq ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (i) ERRORS, MISTAKES OR INACCURACIES OF DATA OR INFORMATION POSTED, DISPLAYED, PUBLISHED OR MADE AVAILABLE FOR DOWNLOAD OR USE
                ON THE SITE, (ii) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM USE OF THE SITE, (iii) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE,
                OR (iv) THE DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY THIRD PARTY NOT UNDER Desq’S CONTROL.
            </p>
            <p class="mb-4">
                Limitation of Liability. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (a) IN NO EVENT SHALL Desq, ITS AFFILIATES OR THEIR RESPECTIVE EMPLOYEES, CONTRACTORS, AGENTS, OFFICERS OR
                DIRECTORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR BUSINESS INTERRUPTION, LOSS OF
                PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES ARISING OUT OF OR RELATING TO THE SITE; AND (b) IN NO EVENT SHALL Desq’S CUMULATIVE AND AGGREGATE LIABILITY UNDER THESE TERMS
                EXCEED $100 U.S. DOLLARS. THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION APPLY WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER
                BASIS, EVEN IF YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
            </p>
            <h4>Responsibility for End Users.</h4>
            <p class="mb-4">
                You are responsible for violations of these Terms by anyone using the Websites with your permission or using your account on an unauthorized basis. Your use of the Websites to assist
                another person in an activity that would violate these Terms if performed by you is a violation of these Terms. These Terms applies to anyone accessing or using the Websites; however,
                each provision in these Terms shall be interpreted to include, and apply to, any action directly or indirectly taken, authorized, facilitated, promoted, encouraged or permitted by a
                user of the Websites, even if such person did not themselves violate the provision.
            </p>
            <h4>Digital Millennium Copyright Act</h4>
            <p class="mb-4">
                The Digital Millennium Copyright Act of 1998 (“DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S.
                copyright law. If you believe that any material residing on or linked to from the Websites infringes your copyright, please send (or have your agent send) to Desq’s Copyright Agent a
                notification of claimed infringement with all of the following information: (a) identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works
                are covered by a single notification, a representative list of such works; (b) identification of the claimed infringing material and information reasonably sufficient to permit Desq to
                locate the material on the Websites (such as the URL(s) of the claimed infringing material); (c) information reasonably sufficient to permit Desq to contact you, including an address,
                telephone number and, if available, an email address; (d) the following statement: “I have a good faith belief that use of the copyrighted materials described above as allegedly
                infringing is not authorized by the copyright owner, its agent, or the law”; (e) the following statement: “I swear, under penalty of perjury, that the information in the notification
                is accurate and that I am the copyright owner or am authorized to act on behalf of the owner of an exclusive right that is allegedly infringed”; and (f) your physical or electronic
                signature.
            </p>
            <p class="mb-4">
                To file a counter notification with Desq, please provide the following information to Desq’s Copyright Agent: (a) a description of the material that Desq has removed or to which Desq
                has disabled access; (b) information reasonably sufficient to permit Desq to contact you, including an address, telephone number and, if available, an email address, and a statement
                that you consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located (or the Southern District of New York if your address is
                outside of the United States), and that you will accept service of process from the person who provided notification of infringement or an agent of such person; (c) the following
                statement: “I swear, under penalty of perjury, that I have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification
                of the material to be removed or disabled”; and (d) your physical or electronic signature.
            </p>
            <p class="mb-4">Desq’s Copyright Agent for notification of claimed infringement can be reached as follows:</p>
            <h4>U.S. Government Rights.</h4>
            <p class="mb-4">
                The Websites is provided to the U.S. Government as “commercial items,” “commercial computer software,” “commercial computer software documentation,” and “technical data” with the same
                rights and restrictions generally applicable to the Websites. If you are using the Websites on behalf of the U.S. Government and these terms fail to meet the U.S. Government’s needs or
                are inconsistent in any respect with federal law, you must immediately discontinue use of the Websites. The terms listed above are defined in the Federal Acquisition Regulation and the
                Defense Federal Acquisition Regulation Supplement.
            </p>
            <p class="mb-4">
                Notice for California Residents. Under California Civil Code Section 1789.3, California users are entitled to the following consumer rights notice: Desq does not currently charge any
                fees for access and use of the Websites. If you have a question or complaint regarding the Websites, please contact Desq by email. California residents may reach the Complaint
                Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916)
                445–1254 or (800) 952–5210.
            </p>
            <h4>Termination and Monitoring.</h4>
            <p class="mb-4">
                We may terminate or suspend these Terms at any time without notice to you. Without limiting the foregoing, we shall have the right to immediately terminate your access to the Websites
                in the event of any conduct by you which we, in our sole discretion, consider to be unacceptable, or if you breach these Terms. Desq’s right to suspend or terminate your use of
                Websites applies even if a breach is committed unintentionally or without your authorization if Desq believes that suspension or termination is necessary to ensure compliance with
                Applicable Laws or to protect the rights, safety, privacy, security or property of Desq, its customers or third parties. All provisions of the Terms which by their nature should
                survive termination shall survive termination, including, without limitation, the ownership provisions, Indemnity, Disclaimers and Limitations of Liability.
            </p>
            <p class="mb-4">
                Desq reserves the right, but does not assume the obligation, to investigate any violation of these Terms or misuse of the Websites. Desq has the right in its sole discretion to edit,
                refuse to post or remove any Content posted, displayed, published or made available for download or use on the Websites that Desq finds to be in violation of these Terms. Desq may
                report any activity that it suspects violates any law or regulation to appropriate law enforcement officials, regulators, or other appropriate third parties. Such reporting may include
                disclosing appropriate customer data. Desq also may cooperate with appropriate law enforcement agencies, regulators, or other appropriate third parties to help with the investigation
                and prosecution of illegal conduct by providing network and systems information related to alleged violations.
            </p>
            <h4>Electronic Communications.</h4>
            <p class="mb-4">
                When you visit the Websites or send emails to Desq, you are communicating with Desq electronically; and you consent to receive communications from Desq electronically. Desq will
                communicate with you by email or by posting notices on the Websites. You agree that all agreements, notices, disclosures and other communications that Desq provides to you
                electronically satisfy any legal requirement that such communications be in writing.
            </p>
            <h4>Modifications.</h4>
            <p class="mb-4">Desq may modify these Terms at any time by posting a revised version on the Websites. By accessing the Websites, you agree to the latest version of these Terms.</p>
            <h4>Governing Law.</h4>
            <p class="mb-4">
                This Agreement will be interpreted, construed and enforced in all respects in accordance with the laws of the State of New York, without reference to its choice of law principles. Any
                legal action or proceeding arising under or relating to this Agreement shall be brought exclusively in the state or federal courts located in New York County, New York, USA, and the
                Parties expressly consent to personal jurisdiction and venue in those courts.
            </p>
            <h4>Miscellaneous.</h4>
            <p class="mb-4">
                These Terms constitute the complete and exclusive statement of the agreement between the Parties and supersede all proposals, oral or written, and all other communications between the
                Parties relating to the subject matter of these Terms. In the event any information posted on the Websites from time to time conflicts with any provision of these Terms, the applicable
                provision of these Terms shall control. Any terms and conditions of any other instrument issued by you in connection with these Terms which are in addition to, inconsistent with or
                different from these Terms shall be of no force or effect. These Terms do not, and shall not be construed to, create any partnership, joint venture, employer-employee, agency or
                franchisor-franchisee relationship between you and Desq. You may not assign, transfer or sublicense all or any of your rights or obligations under these Terms without Desq’s express
                prior written consent. Desq may assign, transfer or sublicense all or any of Desq’s rights or obligations under these Terms without restriction. The failure of Desq to exercise or
                enforce any condition, term or provision of these Terms will not operate as a waiver of such condition, term or provision. Any waiver by Desq of any condition, term or provision of
                these Terms shall not be construed as a waiver of any other condition, term or provision. If any provision of these Terms is held invalid or unenforceable, the remainder of these Terms
                shall continue in full force and effect. You agree that a printed version of these Terms and of any notice given in electronic form shall be admissible in judicial or administrative
                proceedings based upon or relating to these Terms to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in
                printed form. Desq will not be responsible for failures to fulfill any obligations due to causes beyond its control. Non-English translations of these Terms are provided for
                convenience only. In the event of any ambiguity or conflict between translations, the English version is authoritative and controls.
            </p>
            <p class="mb-4">Contact. Please contact Desq at info@desquw.com with any questions regarding these Terms.</p>
        </div>
        <h2>Privacy Policy</h2>
        <div>
            <p class="mb-4">Effective Date: October 5th</p>
            <p class="mb-4">
                We at Desq Insurance Services, LLC. and our affiliates and subsidiaries (“Desq,” “we,” “us,” or “our”) know you care about how your personal information is used and shared, and we take
                your privacy seriously. Please read the following to learn more about our privacy practices. By using or accessing our websites at
                <a href="https://www.desquw.com" target="_blank" rel="noopener noreferrer" class="text-decoration-underline">www.desquw.com</a> or other websites that we operate and link to this
                policy (collectively, the “Sites”) in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will
                collect, use, and share your information in the following ways.
            </p>
            <p class="mb-4">
                Desq Insurance Services, LLC. (“Desq,” “we,” “us,” or “our”) provides an innovative risk financing, structuring, and insurance underwriter. Our team’s expertise in alternative risk
                enables us to structure custom solutions for companies experiencing the limitations of the traditional insurance market.
            </p>
            <p class="mb-4">
                Personal information or personal data refers to any data or information that can be used to identify a natural person, and are subject to applicable data protection laws, such as the
                EU General Data Protection Regulation 2016/679 (“GDPR”) or the California Consumer Privacy Act (Assembly Bill 375), as amended (“CCPA”). We use the term “Personal Data” throughout this
                Privacy Statement to mean, as applicable, “personal data” (under the GDPR), “personal information” (under the CCPA), or similarly defined personally identifiable information governed
                by an applicable data protection law that is made available to Desq in connection with the Services.
            </p>
            <p class="mb-4">
                With respect to cases in which Desq collects or receives Personal Data under and/or pursuant to the direction of our Clients, Desq is acting as a data processor (under GDPR) or service
                provider (under CCPA), and our Clients are the data controllers (under GDPR) or businesses (under CCPA) with respect to such Personal Data. To this end, if not stated otherwise in this
                Privacy Statement or in a separate disclosure, we process such Personal Data as a processor/service provider on behalf of our Clients (and their affiliates) who are the
                controller/business that have collected the Personal Data.
            </p>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '@/components/SectionBlock.vue'
    export default {
        name: 'PrivacyPolicy',
        components: {
            SectionBlock,
        },
    }
</script>

<style scoped></style>
