<template>
    <section-block class="hero partner">
        <h2>Desq works with ambitious underwriters with a knack for innovation.</h2>
        <div class="row mt-4">
            <div class="col-12 col-md-6 my-2">
                <icon-card
                    title="Desq is partnering with the insurance industry’s top Underwriters:"
                    description="<ul><li>Widely regarded as an industry thought leader</li>
                    <li>Extensive experience underwriting a specialized class of business</li>
                    <li>Utilizes data and expertise to outperform competitors</li>
                    <li>Established network of brokers and clients</li></ul>"
                    icon="ribbon.svg"
                    :hero="true"
                />
            </div>
            <div class="col-12 col-md-6 my-2">
                <icon-card
                    title="Target Classes and Lines of Business:"
                    description="<ul><li>Commercial insurance written on E&S paper</li>
                    <li>Data-driven, information and innovation arbitrage</li>
                    <li>Dislocated market opportunities</li>
                    <li>Complex, specialized, niche business segments</li>
                    <li>Dynamic rating models</li></ul>"
                    icon="target.svg"
                    :hero="true"
                />
            </div>
        </div>
    </section-block>
    <section-block class="card-container">
        <div class="small micro-heading-yellow">A Framework for Success</div>
        <h2 class="my-2">Trusted Carrier Relationships</h2>
        <div class="font-align-left mb-4">
            Carriers with an appetite for program business can partner with Desq and receive immediate, time, cost, and resource efficiencies building and managing their programs portfolio.
        </div>
        <div class="row">
            <div class="col-12 col-md-4 my-2">
                <drawer-card title="Pre-Vetted Programs" description="Access to programs and underwriters that have gone through Desq’s diligence and selection process" class="light-card me-lg-1" />
            </div>
            <div class="col-12 col-md-4 my-2">
                <drawer-card
                    title="Fixed Expense Model"
                    description="Transform variable in-house underwriting expenses into a fixed cost model. Greater certainty and management over expense ratios. "
                    class="dark-card mx-lg-1"
                />
            </div>
            <div class="col-12 col-md-4 my-2">
                <drawer-card
                    title="Pre-Approved Underwriting Tech & Infrastructure"
                    description="Desq’s underwriting tech, infrastructure, and operations have been vetted and built to adhere to carrier general agent agreement requirements"
                    class="light-card ms-lg-1"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 my-2">
                <drawer-card title="Carrier Portal" description="Manage your program’s book and reporting through Desq’s state-of-the-art Carrier Portal" class="dark-card me-lg-1" />
            </div>
        </div>
    </section-block>
</template>
<script>
    import SectionBlock from '@/components/SectionBlock'
    import IconCard from '@/components/IconCard'
    import DrawerCard from '@/components/DrawerCard'
    export default {
        name: 'Partner',
        components: { DrawerCard, IconCard, SectionBlock },
    }
</script>

<style scoped></style>
