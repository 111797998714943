<template>
    <section-block class="hero launch-top">
        <h2 class="fw-light">Desq partners with entrepreneurial underwriters to launch and manage unique, tech-enabled, MGAs.</h2>
        <div class="font-align-left my-3">Our Build-to-Scale framework provides our underwriters with the most efficient and cost-effective solution to launch and manage their program.</div>
        <router-link to="/contact" @click="scrollToTop"> <button class="button-dark">Get in Touch</button></router-link>
    </section-block>
    <section-block class="card-container">
        <div class="small micro-heading-yellow">Build-to-Scale</div>
        <h2 class="my-2">Providing You with Opportunity</h2>
        <div class="font-align-left mb-4">
            You’ll have access to a wealth of support and opportunities that will be invaluable to the development and management of your program. You underwrite, we do the rest.
        </div>
        <div class="row">
            <div class="col-12 col-md-4 my-2">
                <drawer-card
                    title="Underwriting Work Station"
                    description="A customized, cloud-based underwriting IT system that includes CRM, underwriting, rating, quoting, binding, invoicing and policy issuance capabilities."
                    class="light-card me-lg-1"
                />
            </div>
            <div class="col-12 col-md-4 my-2">
                <drawer-card
                    title="Start-Up Funding & Growth Capital"
                    description="We can provide funding to get you started until the MGA begins to develop positive cash flow as well as access to our network of venture investors."
                    class="dark-card mx-lg-1"
                />
            </div>
            <div class="col-12 col-md-4 my-2">
                <drawer-card
                    title="Access to Reinsurance Capacity"
                    description="Through our extensive network of insurers, reinsurers and brokers we will assist you in finding, structuring and securing high-quality paper to support your Program."
                    class="light-card ms-lg-1"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 my-2">
                <drawer-card
                    title="Underwriting Administration & Support"
                    description="Back-end functional support to manage your Program and underwriting team, including payroll, HR, and benefits."
                    class="dark-card me-lg-1"
                />
            </div>
            <div class="col-12 col-md-4 my-2">
                <drawer-card
                    title="Program Compliance"
                    description="Desq is P&C and E&S licensed in all 50 states and provides the governance, regulatory and compliance structure required by program carriers."
                    class="light-card mx-lg-1"
                />
            </div>
            <div class="col-12 col-md-4 my-2">
                <drawer-card
                    title="Mentoring and Guidance"
                    description="Our expert team of underwriting, actuarial, operational and legal experts will help craft and position your Program for success."
                    class="dark-card ms-lg-1"
                />
            </div>
        </div>
    </section-block>
    <section-block>
        <h2>Your Program, Powered by Desq</h2>
        <div class="font-align-left">
            Our proprietary underwriting platform, program expertise, relationships, and operational support will accelerate the launch of your Program in a matter of months. See how you can launch
            your Program in 4 simple phases:
        </div>
        <ol>
            <li>
                <h4 class="fw-bold">Business Concept and Plan</h4>
                <div class="font-align-left">Our team will assist you with building your business, marketing, and underwriting plan to secure support from top (re)insurers.</div>
            </li>
            <li>
                <h4 class="fw-bold">Committed Insurance and Reinsurance</h4>
                <div class="font-align-left">
                    Through our extensive industry network we will work with you to find, structure and negotiate the best possible risk-taking capacity support for your Program.
                </div>
            </li>
            <li>
                <h4 class="fw-bold">Build</h4>
                <div class="font-align-left">
                    Our team will work with you to build a bespoke Underwriting Workstation and put in place all the other regulatory and back-office services required to operate the Program.
                </div>
            </li>
            <li>
                <h4 class="fw-bold">Launch & Scale</h4>
                <div class="font-align-left">Desq’s infrastructure and operational support system is built to allow your Program to launch and scale at a rapid rate.</div>
            </li>
        </ol>
    </section-block>
    <section-block class="hero launch-bottom">
        <h2>The Desq Difference</h2>
        <div class="row">
            <div class="col-12 col-md-6 my-2">
                <icon-card title="Valuable Industry Experience" description="We’ll partner and consult with you at the concept phase." icon="lightbulb.svg" />
            </div>
            <div class="col-12 col-md-6 my-2">
                <icon-card title="Program Ownership" description="Our favorable economic terms allow you to retain significant ownership of your Program." icon="homeInHand.svg" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 my-2">
                <icon-card title="Pathway to Exit" description="Our framework allows you to scale your Program into your own autonomous MGA or other liquidity event." icon="maze.svg" />
            </div>
            <div class="col-12 col-md-6 my-2">
                <icon-card title="Custom Workstation" description="Purpose-built and bespoke underwriting workstation designed specifically for your Program. " icon="gear.svg" />
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '@/components/SectionBlock'
    import DrawerCard from '@/components/DrawerCard'
    import IconCard from '@/components/IconCard'
    import { utilMixin } from '@/utilMixin'
    export default {
        name: 'Launch',
        components: { IconCard, DrawerCard, SectionBlock },
        mixins: [utilMixin],
    }
</script>

<style scoped></style>
