<template>
    <navigation-bar />
    <router-view />
    <footer-bar />
</template>

<script>
    import NavigationBar from '@/components/NavigationBar'
    import FooterBar from '@/components/FooterBar'
    export default {
        name: 'App',
        components: { NavigationBar, FooterBar },
    }
</script>
