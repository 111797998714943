<template>
    <div class="component-hamburger">
        <button class="hamburger navbar-toggler" type="button" :class="{ 'is-active': isExpanded }">
            <span class="hamburger-box">
                <span class="hamburger-inner" />
            </span>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'Hamburger',
        props: {
            isExpanded: Boolean,
        },
    }
</script>
