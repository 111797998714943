<template>
    <div class="footer">
        <div class="footer-container desktop">
            <h2 class="left">Looking for a fast-track to launching your own program?</h2>
            <router-link class="right" to="/contact" @click="scrollToTop"> <button class="button-light">Contact Us</button></router-link>
        </div>
        <div class="footer-container mobile-footer">
            <div class="col">
                <h2 class="row">Looking for a fast-track to launching your own program?</h2>
                <div class="row mt-4">
                    <router-link to="/contact" class="ps-0" @click="scrollToTop"> <button class="button-light">Contact Us</button></router-link>
                </div>
            </div>
        </div>
        <hr />
        <div class="row pt-4">
            <div class="col-12 col-md-3">
                <a href="/">
                    <img class="logo" src="../assets/logoLight.png" />
                </a>
                <div class="small my-2">
                    <div>Desq Insurance Services, LLC</div>
                    <div>650 Fifth Ave, 27th Floor</div>
                    <div>New York, NY 10019</div>
                    <div>info@desquw.com</div>
                </div>
            </div>
            <div class="col-12 col-md-3"></div>
            <div class="col-12 col-md-3">
                <h5 class="mb-1">
                    <router-link to="/" @click="scrollToTop">Launch a Program</router-link>
                </h5>
                <h5 class="mb-1"><router-link to="/partner" @click="scrollToTop">Partner with Desq</router-link></h5>
                <h5 class="mb-1"><router-link to="/team" @click="scrollToTop">Meet the Team</router-link></h5>
                <h5 class="mb-1"><router-link to="/programs" @click="scrollToTop">Programs</router-link></h5>
            </div>
            <div class="col-12 col-md-3">
                <div class="mb-1">
                    <router-link to="/policy" @click="scrollToTop"><div class="cyan">Privacy Policy</div></router-link>
                </div>
                <div class="mb-1">
                    <router-link to="/contact" @click="scrollToTop"><div class="cyan">Contact Us</div></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { utilMixin } from '@/utilMixin'

    export default {
        name: 'FooterBar',
        mixins: [utilMixin],
    }
</script>
