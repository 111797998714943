<template>
    <section-block class="hero team">
        <h2>Origins & Mission</h2>
        <div class="font-align-left">
            Desq was founded in 2022 as an MGA accelerator focusing on commercial P&C Managing General Agencies (MGAs). Desq provides guidance, support, services and funding to launch and manage MGAs.
            Desq is a subsidiary of USQRisk and has significant funding to support the growth of the business.
        </div>
    </section-block>
    <section-block class="card-container">
        <h2>Pera Program Leadership</h2>
        <team-card
            class="mt-4"
            name="Jeffrey Batt"
            portrait="jeffreyBatt.png"
            role="Chief Underwriting Officer, Head of Cyber"
            bio="Jeffrey Batt is Pera's Chief Underwriting Officer and Head of Cyber, leading all underwriting, strategy, distribution, and product innovation. Jeffrey previously held senior roles at Trium Cyber and AmTrust, where he helped successfully build and launch new cyber insurance capacity in the large corporate risk space."
            bio2="A former Associate Deputy General Counsel at the U.S. Department of Defense (''DoD'') and senior client advisor at Marsh, Jeffrey also has a deep academic background and technical understanding of cyber risk. He received his Juris Doctor from the Georgetown University Law Center, Bachelor of Arts from Hamilton College, and a Certificate in Cyber Leadership from the DoD-affiliated National Defense University."
            linkedIn="https://www.linkedin.com/in/jbattdc/"
        />
    </section-block>
    <section-block>
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="small micro-heading-blue">Reputable Foundations</div>
                <div class="my-2 font-align-left">
                    As Desq's dedicated partner, underwriters can leverage USQ's team, capabilities, and collective experience to support the build out and growth of their MGA.
                </div>
            </div>
            <div class="col-12 col-md-4">
                <img src="../assets/usqRisk.png" alt="USQ" class="usq-risk-logo" />
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '@/components/SectionBlock'
    import TeamCard from '@/components/TeamCard'
    export default {
        name: 'Team',
        components: { TeamCard, SectionBlock },
    }
</script>

<style scoped></style>
